// import './BarcodeReader/index';

import { Elm } from './Main.elm';
import logo from './assets/logo.png';

// C R E D E N T I A L S

const CREDENTIALS_STORAGE_KEY = 'credentials';

const readCredentials = (): null | string => localStorage.getItem(CREDENTIALS_STORAGE_KEY)

const saveCredentials = (credentials: string, expireAt: number, cb: () => void): void => {
    localStorage.setItem(CREDENTIALS_STORAGE_KEY, credentials);
}

const removeCredentials = (): void => {
    localStorage.removeItem(CREDENTIALS_STORAGE_KEY);
}

const CUSTOMER_ORDER_STORAGE_KEY = 'customer_order_filters';
const DISPATCH_ORDER_STORAGE_KEY = 'dispatch_order_filters';
const RECEIVE_ORDER_STORAGE_KEY = 'receive_order_filters';
const SUPPLIER_ORDER_STORAGE_KEY = 'supplier_order_filters';
const SUPPLIER_ORDERS_STORAGE_KEY = 'supplier_orders_filters';
const PUSH_API_KEY = 'BO0qZqgo4EAMjBgTwwGTysw7Nvze658CRmO-3kRjfJ3dNJrQZR0MxfyeMJwvoqbN-EuMRD2Vjk9Jc6SQ8PXW1Dc';

const readFilters = (key : string): null | string => localStorage.getItem(key) || ""

const saveFilters = (key : string, nextFilters: string): void => {
    localStorage.setItem(key, nextFilters);
}

// A U T H



// A P P L I C A T I O N

var apiUrl;
var wsUrl : string;
if (window.location.host === "app.texenergo.com") {
  apiUrl = "https://v4.texenergo.com/api";
  wsUrl = "wss://v4.texenergo.com/socket";
} else {
  apiUrl = "http://localhost:3001/api";
  wsUrl = "ws://localhost:3001/socket";
}

const app = Elm.Main.init({
    flags: {
        credentials: readCredentials(),
        apiUrl: apiUrl,
        images: {
            logo
        }
    }
});

app.ports.credentials__sign_out.subscribe(() => {
    removeCredentials()
});

app.ports.credentials__write_to_local.subscribe((payload: {
    id_token: string;
    expire_at: number;
}) => {
    saveCredentials(payload.id_token, payload.expire_at, () => {});
});

app.ports.customer_order__read_filters.subscribe(() => {
    app.ports.customer_order__got_filters.send(readFilters(CUSTOMER_ORDER_STORAGE_KEY));
});

app.ports.customer_order__save_filters.subscribe((payload: string) => {
    saveFilters(CUSTOMER_ORDER_STORAGE_KEY, payload);
});

app.ports.dispatch_order__read_filters.subscribe(() => {
    app.ports.dispatch_order__got_filters.send(readFilters(DISPATCH_ORDER_STORAGE_KEY));
});

app.ports.dispatch_order__save_filters.subscribe((payload: string) => {
    saveFilters(DISPATCH_ORDER_STORAGE_KEY, payload);
});

app.ports.receive_order__read_filters.subscribe(() => {
    app.ports.receive_order__got_filters.send(readFilters(RECEIVE_ORDER_STORAGE_KEY));
});

app.ports.receive_order__save_filters.subscribe((payload: string) => {
    saveFilters(SUPPLIER_ORDERS_STORAGE_KEY, payload);
});

app.ports.supplier_order__read_filters.subscribe(() => {
    app.ports.supplier_order__got_filters.send(readFilters(SUPPLIER_ORDER_STORAGE_KEY));
});

app.ports.supplier_order__save_filters.subscribe((payload: string) => {
    saveFilters(SUPPLIER_ORDER_STORAGE_KEY, payload);
});

app.ports.supplier_orders__read_filters.subscribe(() => {
    app.ports.supplier_orders__got_filters.send(readFilters(SUPPLIER_ORDERS_STORAGE_KEY));
});

app.ports.supplier_orders__save_filters.subscribe((payload: string) => {
    saveFilters(SUPPLIER_ORDERS_STORAGE_KEY, payload);
});

app.ports.save_file.subscribe((tuple : [string, string]) => {
  const fileName = tuple[0];
  const baseEncoded = tuple[1];

  var element = document.createElement('a');
  element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(baseEncoded));
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
})

app.ports.general__navigate_to.subscribe((url : string) => {
    window.open(url);
})

let subscriptionGetter = new Promise((myResolve, myReject) => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((worker: ServiceWorkerRegistration) => {
      worker.pushManager.getSubscription().then((subscription) => { myResolve(subscription) });
    })
  } else {
    myReject(null);
  }
});

app.ports.push_api__subscribe.subscribe(() => {
  console.log("push_api__subscribe");
  subscriptionGetter
    .then((subscription) => {
      if (subscription !== null) {
        console.log('User IS subscribed.', subscription.toJSON());
      } else {
        console.log('User is NOT subscribed.');
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(PUSH_API_KEY)
        };
        navigator.serviceWorker.ready.then((worker: ServiceWorkerRegistration) => {
          worker.pushManager.subscribe(subscribeOptions).then((newSubscription) => {
            console.log("Subscription promise resolved to", newSubscription);
            app.ports.push_api__subscription_created.send(subscriptionToJson(newSubscription))
          });
        })
      }
    });
});

app.ports.push_api__subscription_get.subscribe(() => {
  console.log("push_api__subscription_get");
  subscriptionGetter
      .then((subscription) => {
        console.log(subscriptionToJson(subscription));
        if (subscription !== null) {
          app.ports.push_api__subscription_got.send(subscriptionToJson(subscription));
        }
      });
});

app.ports.push_api__subscription_delete.subscribe(() => {
  console.log("push_api__subscription_delete");
  subscriptionGetter
      .then((subscription) => {
        if (subscription !== null) {
          console.log("push_api__subscription_delete subscription is NOT null");
          subscription.unsubscribe()
          .then((successful) => {
            console.log("Unsubscribed", successful);
            app.ports.push_api__subscription_deleted.send(subscriptionToJson(subscription));
          })
          .catch((e) => {
            console.log("Couldn't unsubscribe", e)
          });
        } else {
          console.log("push_api__subscription_delete subscription is null");
        }
      });
});

let subscriptionToJson = function(subscription) {
  const json = subscription.toJSON();
  return {
    endpoint: json.endpoint,
    auth: json.keys.auth,
    p256dh: json.keys.p256dh
  };
}


/**
 * W E B   S O C K E T
 */

let wsCounter = 0;
let unauthFlag = false;
var queue: Array<unknown> = [];
var ws : WebSocket;
var wsSubscribeCalled = false;

// To be called when the app calls Elm's send port.
// If the WS is ready then send immediately, otherwise add to the queue for a delayed dispatch.
const send = (message: unknown): void => {
    console.log("Send called", message)
    if (ws === undefined || ws.readyState !== ws.OPEN) {
        console.log("Pushing", message)
        queue.push(message);
    } else if (ws.readyState === ws.OPEN) {
        console.log("Sending", message)
        ws.send(JSON.stringify(message))
    }
}

const connectWS = (token: string): void => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const exponential = 2 ** wsCounter - 1;
    console.log(`exponential: ${exponential}`)
    const timerValue = Math.random() * exponential * 1000; // seconds
    console.log(`timerValue: ${timerValue}`)

    setTimeout(() => {
        if (unauthFlag) {
            return;
        }

        // Prepend auth token only if it is not already there. Otherwise, we'll flood with auth messages.
        if (queue[0] === undefined || queue[0]["user_id"] === undefined ) {
          queue.unshift({ user_id: token });
        }

        ws = new WebSocket(wsUrl);

        const kill = (): void => {
            if (ws.readyState === ws.CONNECTING || ws.readyState === ws.OPEN) {
                ws.close();
            }
        }

        // Connection opened
        // Immediately dispatch the whole queue.
        ws.addEventListener('open', (): void => {
            console.log("On open")
            if (!wsSubscribeCalled) {
              app.ports.web_socket__send.subscribe(send);
            }
            while (queue.length > 0) {
                ws.send(JSON.stringify(queue.shift()));
            }
            wsCounter = 0;
            app.ports.web_socket__on_open.send(null);
        });

        ws.addEventListener('close', (): void => {
            if (wsSubscribeCalled) {
              app.ports.web_socket__send.unsubscribe(send);
            }
            wsSubscribeCalled = false;
            app.ports.web_socket__kill.unsubscribe(kill);
            app.ports.web_socket__on_close.send(token);
            wsCounter += 1;
            connectWS(token);
        });

        // Listen for messages
        ws.addEventListener('message', (event): void => {
            if (event.data === "401") {
                unauthFlag = true;
            }

            app.ports.web_socket__on_message.send(event.data);
        });

        app.ports.web_socket__kill.subscribe(kill);
    }, timerValue);
}
app.ports.web_socket__send.subscribe(send);
wsSubscribeCalled = true;
app.ports.web_socket__connect.subscribe(connectWS);

const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register('./ServiceWorker.js').then(
    (registration) => {
      console.log("Registration", registration);
      registration.update();
    },
    (error) => {
      console.error(`Service worker registration failed: ${error}`);
    },
  );
  new Worker('./ServiceWorker.js');
}
